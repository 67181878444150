import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import pic04 from '../assets/images/archetypes/MJ/new/rebel2.webp'
import Graph from '../components/graph'

import Mail from '../components/mail'
import MyVideo from '../components/video'
import { Link } from "gatsby"
import More from '../components/more'

class Result extends React.Component {



  constructor(props) {

    super(props);
    console.log(props);


    this.state = this.props.location.state;



  }


  componentWillMount() {
    console.log("result page");
    console.log(this.state);
  }



  render() {
    let name = "Rebel";
    return (
      <Layout>
        <Helmet title={name} />
        <HeaderGeneric text={name} subtext="Lets go of who he was so he can become who he might be."/>
        <div id="main">
          <section id="content" className="main">
            <span className="image main"><img src={pic04} alt="" /></span>


            <h2>{name}</h2>
            
            <p>The Destroyer archetype is a force to be reckoned with. It embodies the repressed rage and anger that we all feel towards the structures in our lives that no longer serve us, whether it be societal norms or our own conscious choices. The Destroyer is a ruthless character, one who will stop at nothing to tear down these structures and make way for new growth.
But make no mistake, the Destroyer is not just a mindless force of destruction. Behind their destructiveness lies a deep fear of annihilation, a fear of losing everything they hold dear. This fear drives them to fight tirelessly, even if it means putting themselves and others in danger.
The true quest of the Destroyer is to find balance, to let go of their anger and find the life drive that will sustain them. They live on the cusp of life and death, and in their moments of humility, they realize that their destructiveness is not just about tearing down, but about building something new and better.
But beware, if you do not integrate this archetype, it will control you and slowly ruin your life and relationships. The only way to harness the power of the Destroyer is to understand it and learn how to channel it in a positive way. To learn more about the Destroyer archetype and how to integrate it into your life, I highly recommend reading <a href={"https://jilecek.itch.io/the-inner-world-of-archetypes?utm_source=archetypes&utm_campaign=destroyer"}>"The Inner World of Archetypes: A Guide to Integrating the 12 Jungian Archetypes"</a> e-book. This powerful guide will help you understand this archetype and how to use its power to create positive change in your life. Remember, the Destroyer is not something to be feared, but something to be understood and embraced.
</p>
<More arch={name}/>
{this.state ? (
  <div>
    <Graph best={this.state.best}  data ={[
{

"id": this.state.names_arr[0],
"label": this.state.names_arr[0],
"value": this.state.vals_arr[0],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[1],
"label": this.state.names_arr[1],
"value": this.state.vals_arr[1],
"color": "hsl(336, 70%, 50%)"
},
{
"id": this.state.names_arr[2],
"label": this.state.names_arr[2],
"value": this.state.vals_arr[2],
"color": "hsl(60, 70%, 50%)"
},
{
"id": this.state.names_arr[3],
"label": this.state.names_arr[3],
"value": this.state.vals_arr[3],
"color": "hsl(54, 70%, 50%)"
},
{
"id": this.state.names_arr[4],
"label": this.state.names_arr[4],
"value": this.state.vals_arr[4],
"color": "hsl(14, 70%, 50%)"
},
{

"id": this.state.names_arr[5],
"label": this.state.names_arr[5],
"value": this.state.vals_arr[5],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[6],
"label": this.state.names_arr[6],
"value": this.state.vals_arr[6],
"color": "hsl(336, 70%, 50%)"
},
{
"id": this.state.names_arr[7],
"label": this.state.names_arr[7],
"value": this.state.vals_arr[7],
"color": "hsl(60, 70%, 50%)"
},
{
"id": this.state.names_arr[8],
"label": this.state.names_arr[8],
"value": this.state.vals_arr[8],
"color": "hsl(54, 70%, 50%)"
},
{
"id": this.state.names_arr[9],
"label": this.state.names_arr[9],
"value": this.state.vals_arr[9],
"color": "hsl(14, 70%, 50%)"
},
{

"id": this.state.names_arr[10],
"label": this.state.names_arr[10],
"value": this.state.vals_arr[10],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[11],
"label": this.state.names_arr[11],
"value": this.state.vals_arr[11],
"color": "hsl(336, 70%, 50%)"
}
]}/>

<Mail best={this.state.best}/>
<MyVideo/>
</div>




) : (
  <div><i>Take the test on the <a href="/">main page</a> to see your archetype breakdown.</i>


  <br/>
<MyVideo/>

  </div>
)}
          </section>

        </div>
      </Layout>
    )
  }
}

export default Result
